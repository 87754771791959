import { z } from 'zod';

import { countryCodeEnum } from '@yonderlabs-packages/schemas-country';
import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';
import { pensionProviderEnum } from '@yonderlabs-packages/schemas-pension-providers';

import {
  apiResponseFailureSchema,
  apiResponseSuccessSchema,
  paginatedApiResponseSuccessSchema,
} from '../base/apiResponse';

const contributionPlanStatusEnum = z.enum(['Active', 'Inactive']);

const contributionPlanNameSchema = z
  .string()
  .min(3, 'Plan Name must not be less than 3 characters')
  .max(100, 'Plan Name cannot be more than 100 characters');

export const contributionPlansSchema = z.object({
  countOfPeopleEnrolled: z.number(),
  countryCode: z.enum(['IE']),
  currencyCode: z.enum(['EUR']),
  currentCost: z.number(),
  id: z.string().uuid(),
  includedBenefits: z.array(z.string()),
  maximumContribution: z.number().nullable(),
  name: contributionPlanNameSchema,
  status: contributionPlanStatusEnum,
});

export type DashboardContributionPlan = z.infer<typeof contributionPlansSchema>;

const contributionPlanProvider = z.object({
  name: z.string(),
  imageUrl: z.string(),
});

export type ContributionPlanProvider = z.infer<typeof contributionPlanProvider>;

const contributionPlanProviders = z.array(contributionPlanProvider);

/**
 * None means no health, if whole object is null then it will default to None
 *! Flat needs Percentage <== THIS IS WHAT WE ARE USING FOR NOW
 **/
export const healthContributionTypeEnum = z.enum(['None', 'Flat', 'Percentage', 'FlatPerMember']);

export type HealthContributionType = z.infer<typeof healthContributionTypeEnum>;

const createHealthContributionSchema = z.object({
  monthlyAmount: z.number().positive('Contribution must be greater than zero'),
  contributionType: healthContributionTypeEnum,
});

/**
 * None means no pension, if whole object is null then it will default to None
 *! Linear Match needs Percentage <== THIS IS WHAT WE ARE USING FOR NOW
 * Ratio Match needs Percentage and Ratio
 * Flat Contribution needs Percentage
 * ThresholdMatch needs Percentage and MinimumEmployeePercentage
 **/
export const pensionContributionTypeEnum = z.enum([
  'None',
  'LinearMatch',
  'RatioMatch',
  'FlatContribution',
  'MinimumFlatContribution',
  'ThresholdMatch',
]);

export type PensionContributionType = z.infer<typeof pensionContributionTypeEnum>;

const createPensionContributionSchema = z.object({
  contributionType: pensionContributionTypeEnum,
  percentage: z.number().min(0).max(100),
  ratio: z.optional(z.number().min(0).max(100)),
  minimumEmployeePercentage: z.optional(z.number().min(0).max(100)),
});

const contributionSchema = z.object({
  coverage: z.number().min(0),
  maxContribution: z.number().min(0),
});

const sassFeeSchema = z.object({
  amount: z.number().min(0),
  currency: currencyCodeEnum,
});

export type SassFee = z.infer<typeof sassFeeSchema>;
export type Contribution = z.infer<typeof contributionSchema>;

const extendedContributionSchema = z.object({
  activationPercentage: z.number().max(100).min(0),
  contribution: z.number().min(0),
  providers: contributionPlanProviders,
  maximumContribution: z.number().min(0),
  monthlyContribution: z.number().min(0),
});

const healthContributionSchema = extendedContributionSchema.extend({
  contributionType: healthContributionTypeEnum,
});

const retirementContributionSchema = extendedContributionSchema.extend({
  contributionType: pensionContributionTypeEnum,
});

const contributionPlanDocumentSchema = z.object({
  name: z.string(),
  signatureId: z.string(),
});

export const contributionPlanUKMetadataSchema = z.object({
  smart: z.object({
    companyId: z.number(),
    groupId: z.number(),
  }),
});

export const contributionPlanNonUKMetadataSchema = z.object({});

export type ContributionPlanNonUKMetadata = z.infer<typeof contributionPlanNonUKMetadataSchema>;

export const contributionPlanMetadataSchema = contributionPlanUKMetadataSchema.or(contributionPlanNonUKMetadataSchema);

export type ContributionPlanMetadata = z.infer<typeof contributionPlanMetadataSchema>;

const contributionPlanSchema = z.object({
  id: z.string().uuid(),
  billingEntityId: z.string().uuid(),
  name: contributionPlanNameSchema,
  countryCode: countryCodeEnum,
  currencyCode: currencyCodeEnum,
  totalContribution: z.number(),
  totalActivationPercentage: z.number().max(100).min(0),
  status: contributionPlanStatusEnum,
  healthCoverage: healthContributionSchema.nullable(),
  retirementCoverage: retirementContributionSchema.nullable(),
  metadata: contributionPlanMetadataSchema,
});

export const countryContributionPlanMetadataSchema = z.discriminatedUnion('type', [
  contributionPlanUKMetadataSchema.extend({
    type: z.literal('GB'),
  }),
  contributionPlanNonUKMetadataSchema.extend({
    type: z.literal('EU'),
  }),
]);

export type ContributionPlan = z.infer<typeof contributionPlanSchema>;

const memberContributionSchema = z.object({
  employerContribution: z.number(),
  employeeContribution: z.number(),
});

export const memberHealthStatusEnum = z.enum(['None', 'Invited', 'Enrolled', 'DroppedOut']);

const memberHealthContributionSchema = memberContributionSchema.extend({
  status: memberHealthStatusEnum,
});

export type MemberHealthContribution = z.infer<typeof memberHealthContributionSchema>;

const memberRetirementContributionSchema = memberContributionSchema.extend({
  employerContributionPercentage: z.number(),
  employeeContributionPercentage: z.number(),
});

export type MemberRetirementContribution = z.infer<typeof memberRetirementContributionSchema>;

const planMemberSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  grossSalary: z.number(),
  countryCode: countryCodeEnum,
  salaryCurrency: currencyCodeEnum,
  health: memberHealthContributionSchema.nullable(),
  retirement: memberRetirementContributionSchema.nullable(),
});

export type PlanMember = z.infer<typeof planMemberSchema>;

const providerTypeEnum = z.enum(['Health', 'Pension']);

export type ProviderType = z.infer<typeof providerTypeEnum>;

const contributionDetailSchema = z.object({
  low: z.number().min(0),
  standard: z.number().min(0),
  competitive: z.number().min(0),
  lowCustom: z.number().min(0),
  highCustom: z.number().min(0),
});

export type ContributionDetail = z.infer<typeof contributionDetailSchema>;

const providerDetailSchema = z.object({
  name: z.string(),
  iconUrl: z.string().url(),
  providerType: providerTypeEnum,
});

export type ProviderDetail = z.infer<typeof providerDetailSchema>;

export const contributionPlanDetailSchema = z.object({
  healthContribution: contributionDetailSchema.or(z.null()),
  retirementContribution: contributionDetailSchema.or(z.null()),
  providers: z.array(providerDetailSchema),
});

export type ContributionPlanDetail = z.infer<typeof contributionPlanDetailSchema>;

const createOrUpdateOrganizationContributionPlanResponseSchema = z.object({
  contributionPlanId: z.string().uuid(),
  country: countryCodeEnum,
  name: contributionPlanNameSchema,
  members: z.number().min(0),
  people: z.array(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      workEmail: z.string().email(),
      salary: z.number().min(0),
      currency: currencyCodeEnum,
    }),
  ),
  pension: contributionSchema.nullable(),
  health: contributionSchema.nullable(),
  saasFees: sassFeeSchema,
  total: z.number().min(0),
  currency: currencyCodeEnum,
  billingInformationId: z.string().uuid(),
  billingCountryCode: countryCodeEnum,
});

export const getOrganizationContributionPlansSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(contributionPlansSchema),
});

export const getPlansErrorsEnum = z.enum(['UnknownError']);

export const getOrganizationContributionPlansErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getPlansErrorsEnum,
});

export const getOrganizationContributionPlansQueryParamsSchema = z.object({
  nameQuery: contributionPlanNameSchema.optional(),
});

export const getOrganizationContributionPlanSuccessSchema = apiResponseSuccessSchema.extend({
  data: contributionPlanSchema,
});

export const getPlanErrorsEnum = z.enum(['UnknownError', 'PlanDoesNotExist']);

export const getOrganizationContributionPlanErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getPlanErrorsEnum,
});

export const getOrganizationContributionPlanPathSchema = z.object({
  planId: z.string().uuid(),
});

export const getOrganizationContributionPlanMembersSuccessSchema = paginatedApiResponseSuccessSchema.extend({
  data: z.array(planMemberSchema),
});

export const getPlanMembersErrorsEnum = z.enum(['UnknownError', 'PlanDoesNotExist']);

export const getOrganizationContributionPlanMembersErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getPlanMembersErrorsEnum,
});

export const getOrganizationContributionPlanDetailsByCountrySuccessSchema = apiResponseSuccessSchema.extend({
  data: contributionPlanDetailSchema,
});

const getOrganizationContributionPlanDetailsByCountryErrors = z.enum(['UnknownError']);

export const getOrganizationContributionPlanDetailsByCountryErrorSchema = apiResponseFailureSchema.extend({
  errorCode: getOrganizationContributionPlanDetailsByCountryErrors,
});

export const getOrganizationContributionPlanDetailsByCountryQueryParamsSchema = z.object({
  countryCode: countryCodeEnum,
});

export const createOrganizationContributionPlanSchema = z.object({
  name: contributionPlanNameSchema,
  countryCode: countryCodeEnum,
  healthContribution: createHealthContributionSchema.nullable(),
  pensionContribution: createPensionContributionSchema.nullable(),
  billingInformationId: z.string().uuid(),
  documents: z.array(contributionPlanDocumentSchema).optional(),
});

export const createOrganizationContributionPlanSuccessSchema = apiResponseSuccessSchema.extend({
  data: createOrUpdateOrganizationContributionPlanResponseSchema,
});

export const createOrganizationContributionPlanErrorsEnum = z.enum([
  'ContractorsAreNotAllowedForIreland',
  'ContributionMustBeGreaterThanZero',
  'InvalidCurrencyForMembers',
  'MissingPensionContribution',
  'NoBillingInformationAddedForThisCurrencyOrCountry',
  'OrganizationDoesNotExist',
  'OrganizationMissingRegistrationNumber',
  'OrganizationMissingSignatories',
  'OrganizationMissingStagingDate',
  'PensionContributionEmployeeMinimumPercentageError',
  'PensionContributionEmployerPercentageError',
  'PensionContributionTypeError',
  'PeopleDoNotBelongToOrganization',
  'PeopleNotSuitableForPackageMissingBirthday',
  'PeopleNotSuitableForPackageMissingPostCode',
  'PeopleNotSuitableForPackageMissingSex',
  'PeopleNotSuitableForPackageWrongCountry',
  'PlanNameCannotBeEmpty',
  'PlanNameCannotBeMoreThan100Characters',
  'PlanNameMustBeMoreThan3Characters',
  'SaasFeeNotFound',
  'UnknownError',
]);

export const createOrganizationContributionPlanErrorSchema = apiResponseFailureSchema.extend({
  errorCode: createOrganizationContributionPlanErrorsEnum,
});

const createOrganizationContributionPlanDryRunResponseSchema = z.object({
  contributionPlanId: z.string().uuid(),
  country: countryCodeEnum,
  name: contributionPlanNameSchema,
  members: z.number().min(0),
  people: z.array(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      workEmail: z.string().email(),
      salary: z.number().min(0),
      currency: currencyCodeEnum,
    }),
  ),
  pension: contributionSchema.nullable(),
  health: contributionSchema.nullable(),
  saasFees: sassFeeSchema,
  total: z.number().min(0),
  currency: currencyCodeEnum,
  billingInformationId: z.string().uuid(),
  billingCountryCode: countryCodeEnum,
});

export const createOrganizationContributionPlanDryRunSchema = createOrganizationContributionPlanSchema.extend({
  people: z.array(z.string().uuid()),
});

export const createOrganizationContributionPlanDryRunSuccessSchema = apiResponseSuccessSchema.extend({
  data: createOrganizationContributionPlanDryRunResponseSchema,
});

export const createOrganizationContributionPlanDryRunErrorsEnum = createOrganizationContributionPlanErrorsEnum;

export const createOrganizationContributionPlanDryRunErrorSchema = apiResponseFailureSchema.extend({
  errorCode: createOrganizationContributionPlanDryRunErrorsEnum,
});

export const updateOrganizationContributionPlanSchema = z.object({
  organizationContributionPlanId: z.string().uuid(),
  planName: contributionPlanNameSchema.optional(),
  healthContribution: createHealthContributionSchema.optional(),
  pensionContribution: createPensionContributionSchema.optional(),
  documents: z.array(contributionPlanDocumentSchema).optional(),
});

export const updateOrganizationContributionPlanSuccessSchema = apiResponseSuccessSchema.extend({
  data: createOrUpdateOrganizationContributionPlanResponseSchema,
});

export const updatePlanErrorsEnum = createOrganizationContributionPlanErrorsEnum;

export type UpdateOrganizationContributionPlanErrorsEnum = z.infer<typeof updatePlanErrorsEnum>;

export const updateOrganizationContributionPlanErrorSchema = apiResponseFailureSchema.extend({
  errorCode: updatePlanErrorsEnum,
});

export const addPeopleToOrganizationContributionPlanSchema = z.object({
  organizationContributionPlanId: z.string().uuid(),
  peopleId: z.array(z.string().uuid()),
});

export const addPeopleToOrganizationContributionPlanSuccessSchema = apiResponseSuccessSchema;

export const addOrRemovePeopleErrorsEnum = z.enum([
  'ContributionPlanDoesNotExist',
  'PeopleDoNotExist',
  'InvalidCurrencyForMembers',
  'PeopleNotInSameCountryAsPackage',
  'PeopleMissingLine1',
  'PeopleMissingPostCode',
  'PeopleMissingSex',
  'PeopleMissingBirthday',
  'PeopleMissingStartDate',
  'PeopleInvalidTaxId',
  'PeopleMissingEmployeeId',
  'UnknownError',
]);

export const addPeopleToOrganizationContributionPlanErrorSchema = apiResponseFailureSchema.extend({
  errorCode: addOrRemovePeopleErrorsEnum,
});

export const removePeopleFromOrganizationContributionPlanSuccessSchema = apiResponseSuccessSchema;

export const removePeopleFromOrganizationContributionPlanErrorSchema = apiResponseFailureSchema.extend({
  errorCode: addOrRemovePeopleErrorsEnum,
});

export const removePeopleFromOrganizationContributionPlanSchema = addPeopleToOrganizationContributionPlanSchema;

export const setPensionDeferralPeriodPathSchema = z.object({
  planId: z.string().uuid(),
  provider: pensionProviderEnum,
});

export const getPensionDeferralPeriodPathSchema = setPensionDeferralPeriodPathSchema;

export const pensionDeferralPeriodTimeUnitEnum = z.enum([
  'Second',
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
  'Months',
  'Years',
]);

export const pensionDeferralPeriodSchema = z.object({
  timeUnit: pensionDeferralPeriodTimeUnitEnum,
  amount: z.number().nonnegative(),
});

export const setPensionDeferralPeriodSchema = pensionDeferralPeriodSchema;

export const setPensionDeferralPeriodSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.never(),
});

const setPensionDeferralPeriodErrorEnum = z.enum(['PostponementNotAvailable', 'UnknownError']);

export const setPensionDeferralPeriodErrorSchema = apiResponseFailureSchema.extend({
  errorCode: setPensionDeferralPeriodErrorEnum,
});

export const deferralPeriodSchema = z.object({
  deferralTime: pensionDeferralPeriodSchema,
});

export type DefferalPeriod = z.infer<typeof deferralPeriodSchema>;

export const getPensionDeferralPeriodSuccessSchema = apiResponseSuccessSchema.extend({
  data: deferralPeriodSchema,
});

export const getPensionDeferralPeriodErrorSchema = setPensionDeferralPeriodErrorSchema;
